<style lang="scss"></style>
<template>
  <div class="rewardStore-container">
    <div v-if="$vuetify.breakpoint.xsOnly" class="background-mobile-coin-show">
      <div class="mobile-coin-show">
        <p class="mb-0 d-flex align-center justify-space-between">
          <span class="coinText">{{ $t(`label.myCoins`) }}</span>
          <span class="d-flex align-center justify-center">
            <img src="static/image/rewardStore/coinIcon.png" alt="Coin Icon" class="coin-icon" />
            <span class="px-1 rewardBalance">{{ memberInfo.rewardBalance ? memberInfo.rewardBalance : 0 }}</span>
          </span>
        </p>
      </div>
    </div>
    <v-img v-if="$vuetify.breakpoint.xsOnly" class="align-end" :aspect-ratio="360 / 180" :src="`${mediaUrl}/game_banner/Reward_Mobile_${currentLanguage}.jpg`"></v-img>
    <v-img v-else class="align-end" :aspect-ratio="1920 / 400" :src="`${mediaUrl}/game_banner/Reward_Desktop_${currentLanguage}.jpg`"></v-img>
    <v-snackbar class="pa-0" style="z-index:1000 !important;top:25%" light timeout="-1" v-model="alertShow"
                width="fit-content" top centered>
      <v-alert
        v-model="alertShow"
        dismissible
        :icon="alertSuccess ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline'"
        class="mb-0 pa-1"
        :class="alertSuccess ? 'profile-alert-success' : 'profile-alert-failed'"
        light
        close-icon="mdi-close"
      >
        {{ alertMessage }}
      </v-alert>
    </v-snackbar>
    <v-dialog class="confirmation_card_dialog" persistent :value="claimNowConfirmation" style="margin:10px;" :max-width="440">
      <v-form ref="redeemProduct_form">
        <div class="confirmation_card">
          <v-row class="confirmation_card-title" no-gutters :class="$vuetify.breakpoint.mdAndDown ? ' py-4 px-4' : 'py-5 px-5'">
            <v-col cols="11">
              <label class="dialog-title white--text ma-0 text-capitalize">{{ $t(`label.redeemTitle`) }}</label>
            </v-col>
            <v-col cols="1">
              <v-icon class="dialog-close-icon" @click="closeclaimNowConfirmation">mdi-close</v-icon>
            </v-col>
          </v-row>
          <div class="confirmation_card_wrapper">
            <v-row no-gutters class="ma-0 px-3">
              <v-col cols="12" class="d-flex justify-center align-center">
                <v-img :src="selectedProductImage" alt="Selected Product Image" class="heightIMG">
                  <v-img v-if="selectedProductIsHot === 1" src="/static/svg/game-icon-hot.svg" width="25" height="25" class="hot-icon"></v-img>
                </v-img>
              </v-col>
              <v-col cols="12" class="d-flex justify-start align-center">
                <span class="redeemItemText">{{ selectedProductName }}</span>
              </v-col>
            </v-row>
            <v-row no-gutters class="ma-0 pa-3">
              <v-col v-if="gotVariations && gotVariations.length > 0" cols="12" md="4" class="d-flex justify-start" style="gap: 10px;">
                <v-btn
                  v-for="option in gotVariations"
                  :key="option.id"
                  :class="{ 'selected-option': selectedOption === option }"
                  :disabled="option.quantity <= 0"
                  class="selectOptions"
                  @click="selectOption(option)"
                >
                  {{ option.variation_objects }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters class="ma-0 px-3">
              <v-row no-gutters></v-row>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="px-3">
                <label class="redeemItemText ma-0 pb-1 text-capitalize d-block">
                  {{ $t(`field.shipTo`) }}
                  <span class="red--text ml-1">*</span>
                </label>
              </v-col>
            </v-row>
            <v-row no-gutters class="px-3">
              <v-col cols="12">
                <v-text-field
                  class="redeemInfo_field pa-0 ma-0"
                  :enabledLabel="true" v-model="redeemProductObj.name" :isOptional="true"
                  :rules="validator.realnameRules(2, 45)"
                  :placeholder="$t(`fieldHint.fillInFullName`)"></v-text-field>
              </v-col>
            </v-row>
            <v-row class="ma-0 px-3">
              <v-row no-gutters>
                <v-col cols="12">
                  <label class="redeemItemText ma-0 pb-1 text-capitalize d-block">
                    {{ $t(`field.phoneNumber`) }}
                    <span class="red--text ml-1">*</span>
                  </label>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6">
                  <v-select
                    elevation="2"
                    v-model.trim="redeemProductObj.dialCode"
                    class="redeemInfo_field_pn phoneNumberPrefix mr-2 black--text"
                    item-text="text"
                    item-value="value"
                    :items="mobileNumberPrefixList"
                    dense
                    outlined
                    :readonly="true"
                  >
                    <template #prepend-inner>
                      <v-btn icon small>
                        <v-avatar size="20" class="language-button">
                          <img width="20" height="20" :src="`/static/image/country/${currentCurrency}.svg`" alt="khela88 cricket exchange" />
                        </v-avatar>
                      </v-btn>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    class="redeemInfo_field pa-0 ma-0"
                    :enabledLabel="false"
                    v-model.trim="redeemProductObj.mobile"
                    :rules="validator.mobileNumberRules(redeemProductObj.language)"
                    :isOptional="false">
                  </v-text-field>
                  <!--                  <app-form-field-->
                  <!--                      class="redeemInfo_field"-->
                  <!--                      :enabledLabel="false"-->
                  <!--                      v-model.trim="redeemProductObj.mobile"-->
                  <!--                      :rules="validator.mobileNumberRules(redeemProductObj.language)"-->
                  <!--                      :isOptional="false"-->
                  <!--                  ></app-form-field>-->
                </v-col>
              </v-row>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="px-3">
                <label class="redeemItemText ma-0 pb-1 text-capitalize d-block">
                  {{ $t(`field.email`) }}
                  <span class="red--text ml-1">*</span>
                </label>
              </v-col>
            </v-row>
            <v-row no-gutters class="px-3">
              <v-col cols="12">
                <v-text-field
                  class="redeemInfo_field pa-0 ma-0"
                  :enabledLabel="true"
                  v-model.trim="redeemProductObj.email"
                  :isOptional="true"
                  :placeholder="$t(`fieldHint.fillUpHere`)"
                  :rules="validator.emailRules()">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="px-3">
                <label class="redeemItemText ma-0 pb-1 text-capitalize d-block">
                  {{ $t(`field.city`) }}
                  <span class="red--text ml-1">*</span>
                </label>
              </v-col>
            </v-row>
            <v-row no-gutters class="px-3">
              <v-col cols="12">
                <v-text-field
                  class="redeemInfo_field pa-0 ma-0"
                  :enabledLabel="true"
                  v-model="redeemProductObj.city"
                  :isOptional="false"
                  :placeholder="$t(`fieldHint.fillInCity`)"
                  :rules="validator.cityRules(5, 30)"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="px-3">
                <label class="redeemItemText ma-0 pb-1 text-capitalize d-block">
                  {{ $t(`field.postcode`) }}
                  <span class="red--text ml-1">*</span>
                </label>
              </v-col>
            </v-row>
            <v-row no-gutters class="px-3">
              <v-col cols="12">
                <v-text-field
                  class="redeemInfo_field pa-0 ma-0"
                  :enabledLabel="true"
                  v-model.trim="redeemProductObj.postcode"
                  :isOptional="false"
                  :placeholder="$t(`fieldHint.fillInPostcode`)"
                  :rules="validator.postCodeRules(4, 20)"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="px-3">
                <label class="redeemItemText ma-0 pb-1 text-capitalize d-block">
                  {{ $t(`field.address`) }}
                  <span class="red--text ml-1">*</span>
                </label>
              </v-col>
            </v-row>
            <v-row no-gutters class="px-3">
              <v-col cols="12">
                <v-text-field
                  class="redeemInfo_field pa-0 ma-0"
                  :enabledLabel="true"
                  v-model="redeemProductObj.address1"
                  :isOptional="false"
                  :placeholder="$t(`fieldHint.fillInAddress`)"
                  :rules="validator.addressRules(5,50)"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="px-3">
                <label class="redeemItemText ma-0 pb-1 text-capitalize d-block">
                  {{ $t(`field.address2`) }}
                  <span class="red--text ml-1">*</span>
                </label>
              </v-col>
            </v-row>
            <v-row no-gutters class="px-3">
              <v-col cols="12">
                <v-text-field
                  class="redeemInfo_field pa-0 ma-0"
                  :enabledLabel="true"
                  v-model="redeemProductObj.address2"
                  :isOptional="false"
                  :placeholder="$t(`fieldHint.fillInAddress2`)"
                  :rules="validator.addressRules(5,50)"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters class="ma-0 px-3">
              <v-row no-gutters>
                <v-col cols="12" class="d-flex justify-center align-center">
                  <span class="redeemItemText">{{ $t(`label.coinsRequired`) }}</span>
                  <span class="d-flex align-center justify-center">
                <img src="static/image/rewardStore/coinIcon.png" alt="Coin Icon" class="coin-icon" />
                <span class="px-1 rewardBalance">{{ coinRequired }}</span>
              </span>
                </v-col>
              </v-row>
            </v-row>
            <v-row no-gutters class="px-3">
              <v-row no-gutters class="d-flex justify-center align-center">
                <v-btn width="100%" @click="redeemNows" class="redeem-Now">
                  {{ $t(`label.redeemNow`) }}
                </v-btn>
              </v-row>
            </v-row>
          </div>
        </div>
      </v-form>
    </v-dialog>
    <v-dialog class="submitted_card_dialog" persistent :value="requestSubmittedDialog" style="margin:10px;" :max-width="440">
      <div class="submitted_card">
        <v-row class="submitted_card_title" no-gutters :class="$vuetify.breakpoint.mdAndDown ? ' py-5 px-5' : 'py-5 px-5'">
          <v-col cols="11">
            <label class="dialog-title white--text ma-0 text-capitalize d-block full-width">{{ $t(`label.reqSubmitted`) }}</label>
          </v-col>
          <v-col cols="1">
            <v-icon class="dialog-close-icon" @click="closerequestSubmittedDialog">mdi-close</v-icon>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" class="d-flex justify-center align-center">
            <v-img :src="'/static/image/rewardStore/popUp-rewardSent.png'" class="reqConfirmed_image"></v-img>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" class="d-flex justify-center align-center subtitle">
            {{ $t(`label.reqSubmittedSubtitle`) }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" class="d-flex justify-center align-center text-center my-3 des">
            {{ $t(`label.reqSubmittedDes1`) }}{{ getCurrentDateTime() }}. {{ $t(`label.reqSubmittedDes2`) }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" class="d-flex justify-center align-center">
            <v-btn class="item-button-close d-flex align-center justify-center" @click="closerequestSubmittedDialog">{{ $t('label.close') }}</v-btn>
          </v-col>
        </v-row>
      </div>
    </v-dialog>
    <v-dialog class="submitted_card_dialog" persistent :value="showNotEnoughBalanceDialog" style="margin:10px;" :max-width="440">
      <div class="submitted_card">
        <v-row class="submitted_card_title" no-gutters :class="$vuetify.breakpoint.mdAndDown ? ' py-5 px-5' : 'py-5 px-5'">
          <v-col cols="11">
            <label class="dialog-title white--text ma-0 text-capitalize d-block full-width">{{ $t(`label.notEnufBalance`) }}</label>
          </v-col>
          <v-col cols="1">
            <v-icon class="dialog-close-icon" @click="closeNotEnoughBalanceDialog">mdi-close</v-icon>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" class="d-flex justify-center align-center text-center my-3 des">
            {{ $t(`label.notEnufBalanceDes`) }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" class="d-flex justify-center align-center">
            <v-btn class="item-button-close d-flex align-center justify-center" @click="closeNotEnoughBalanceDialog">{{ $t('label.close') }}</v-btn>
          </v-col>
        </v-row>
      </div>
    </v-dialog>
    <!--    <img :src="'/static/image/rewardStore/coinTips.png'" alt="CoinTips" class="coinTipsClass" @click="openCoinTipsDialog()" />-->

    <div v-if="$vuetify.breakpoint.mdAndUp" class="detailContainer">
      <!-- Left Container -->
      <div class="left-container" style="height: 60px;">
        <p class="mb-0 d-flex align-center justify-space-between">
          <span class="coinText">{{ $t(`label.myCoins`) }}</span>
          <span class="d-flex align-center justify-center">
            <img src="static/image/rewardStore/coinIcon.png" alt="Coin Icon" class="coin-icon" />
            <span class="px-1 rewardBalance">{{ reactiveRewardBalance }}</span>
          </span>
        </p>
      </div>

      <!-- Right Container -->
      <div class="right-container" >
        <div class="d-flex ">
          <v-btn
            class="rewardStore-Tab col-2"
            style="height: 60px !important;"
            v-for="category in categories"
            :key="category.value"
            :value="category.value"
            :class="{ selectedCat: selectedCategory === category.value }"
            @click="selectCategory(category.value)"
          >
            {{ $t(`label.${category.label.toLowerCase()}`) }}
            <!--            {{ category.label }}-->
          </v-btn>
        </div>
      </div>
    </div>
    <div v-else class="detailContainer_mobile mb-2">
      <div class="d-flex justify-center categoriesContainer col-12 pa-0">
        <v-row no-gutters class="justify-start align-center">
          <v-col cols="11" class="d-flex justify-start align-center">
            <v-btn
              v-for="category in displayedCategories"
              class="rewardStore-Tab_mobile "
              :class="{ selectedCat: selectedCategory === category.value }"
              @click="selectCategory(category.value)"
            >
              {{ $t(`label.${category.label.toLowerCase()}`) }}
              <!--              {{ category.label }}-->
            </v-btn>
          </v-col>
          <v-col cols="1" v-if="showPreviousButton" class="text-center">
            <v-btn icon @click="previousPage">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="1" v-if="showNextButton" class="text-center">
            <v-btn icon @click="nextPage" >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-if="$vuetify.breakpoint.mdAndUp">
      <v-container class="Product-Container pb-5">
        <v-row style="max-width: 100% !important;">
          <v-col v-for="product in reward" :key="product.id" cols="2" md="2">
            <v-card :class="{ 'inactive-product': product.total_quantity === 0 }" class="product-container">
              <v-img :src="product.image_cdn" height="150">
                <v-img v-if="product.is_hot === 1" src="/static/svg/game-icon-hot.svg" width="25" height="25" class="hot-icon"></v-img>
              </v-img>
              <v-card-title>
                {{ truncatedRewardItemName(product.reward_item_name) }}
              </v-card-title>
              <v-card-subtitle class="subtitle_class">
                <img src="static/image/rewardStore/coinIcon.png" alt="Coin Icon" class="coin-icon" />
                <span class="px-1 points_required">{{ product.points_required }}</span>
              </v-card-subtitle>

              <v-card-actions class="justify-center action_class">
                <v-btn v-if="product.total_quantity > 0" color="#f56908" class="claimNow"
                       @click="handleClaimClick(product)">
                  {{ $t(`label.claimNow`) }}
                </v-btn>
                <v-btn class="claimNow" v-else style="text-transform: none;" disabled>{{ $t(`label.outOfStock`) }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-else>
      <div class="detailContainer_mobile">
        <v-container class="Product-Container">
          <v-row style="max-width: 100%; background-color: #EFF0F5; margin: 0px;">
            <v-col v-for="product in reward" style="padding: 6px;" :key="product.id" cols="6">
              <v-card :class="{ 'inactive-product': product.total_quantity === 0 }" class="product-container">
                <v-img :src="product.image_cdn" height="115">
                  <v-img v-if="product.is_hot === 1" src="/static/svg/game-icon-hot.svg" width="25" height="25" class="hot-icon"></v-img>
                </v-img>
<!--                <v-card-title id="reward_item_title">{{ product.reward_item_name }}</v-card-title>-->
                <v-card-title id="reward_item_title">
                  {{ truncatedRewardItemName(product.reward_item_name) }}
                </v-card-title>
                <v-card-subtitle class="subtitle_class">
                  <img src="static/image/rewardStore/coinIcon.png" alt="Coin Icon" class="coin-icon" />
                  <span class="px-1 points_required">{{ product.points_required }}</span>
                </v-card-subtitle>
                <v-card-actions class="justify-center action_class">
                  <v-btn v-if="product.total_quantity > 0" color="#f56908" class="claimNow" @click="handleClaimClick(product)">{{ $t(`label.claimNow`) }}</v-btn>
                  <v-btn v-else class="claimNow" style="text-transform: none;" disabled>{{ $t(`label.outOfStock`) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div class="text-center mb-6" v-if="rewardPagination.last_page > 1">
      <v-pagination
        class="game_pagination"
        v-model="rewardPagination.current_page"
        :length="rewardPagination.last_page"
        @input="changePage"
        :next-icon="'mdi-chevron-right'"
        :prev-icon="'mdi-chevron-left'"
        :total-visible="rewardPagination.per_page"
      ></v-pagination>
    </div>
  </div>
  </div>
</template>

<script>
import { uiHelper, locale, formValidator, errorCodeHelper } from "@/util";
import { SESSION, SHARED } from '@/constants/constants'
import { ROUTE_NAME } from '@/constants/route.constants'
import {
  MEMBER_CLAIM_REWARD, MEMBER_RESET_CLAIM_REWARD_ITEM,
  MEMBER_RESET_VERIFY_POINTS,
  MEMBER_VERIFY_POINTS,
  REWARD_GET_ITEM
} from "@/store/reward.module";
import { MEMBER_CHANGE_PASSWORD, MEMBER_REGISTER, MEMBER_RESET_REQUEST_FORGOT_PASSWORD } from "@/store/member.module";
import { VOUCHER_BONUS_APPLY_RESET, VOUCHER_BONUS_CHECK_RESET } from "@/store/bonus.module";
export default {
  name: 'rewardStore',
  data: () => ({
    allowSpacing: true,
    alertShow: false,
    alertSuccess: false,
    alertMessage: "",
    dates: [SHARED.DEFAULT_DATE, SHARED.DEFAULT_DATE],
    rewardName: '',
    selectedOption: null,
    currentCurrency: uiHelper.getCurrency(),
    currentLanguage: uiHelper.getLanguage(),
    categoriesPerPage: 4,
    currentPage: 0,
    showNotEnoughBalanceDialog: false,
    requestSubmittedDialog: false,
    redeemProductObj: {
      rewardId: '',
      rewardVariation : '',
      name : '',
      mobile : '',
      email: '',
      city : '',
      postcode : '',
      address1 : '',
      address2 : '',
      currency: SHARED.DEFAULT_CURRENCY,
      dialCode: uiHelper.getDialCode(),
      language: SHARED.DEFAULT_LANGUAGE,

    },
    validator: formValidator,
    mobileNumberPrefixList: [
      {
        text: '+880',
        value: '880',
        currency: 'BDT',
        lang: 'bd'
      }
    ],
    claimNowConfirmation: false,
    routeName: ROUTE_NAME,
    rewardStoreTab: 'All',
    selectedItemId: '',
    selectedProductName: '',
    productSelfCategory: '',
    coinRequired: '',
    creditGiven: '',
    turnoverMultiplier: '',
    gotVariations: '',
    showsextraOptions: [],
    selectedProductImage: '',
    selectedProductIsHot: '',
    mediaUrl: SHARED.MEDIA_SERVER_URL,
    coins: 1000, // Replace with your actual coins value
    selectedCategory: '',
    topRedeemProducts: [],
    categories: [
      { label: 'rw_All', value: '' },
      { label: 'rw_TopRedeem', value: '1' },
      { label: 'rw_Electronics', value: '2' },
      { label: 'rw_HomeLiving', value: '3' },
      { label: 'rw_Accessories', value: '4' },
      { label: 'rw_DataPlan', value: '5' }
    ],
    productSelected: '',
    pagination: {
      currentPage: 1,
      totalPage: 1,
      paginationPageLimit: 10
    },
    verifiedStatus: '',
    verifiedReturnedCode: 2,
    wordLimit: 5
  }),
  computed: {
    reactiveRewardBalance() {
      const rewardBalance = this.memberInfo.rewardBalance || 0;
      return rewardBalance;
    },
    checkingPointsResponse() {
      return this.$store.state.reward.verifyPoints.complete;
    },
    checkingRedeemResponse() {
      return this.$store.state.reward.redeemProduct.complete
    },
    reward() {
      return this.$store.state.reward.rewardItem.data;
    },
    rewardPagination() {
      return this.$store.state.reward.rewardItem.pagination
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    showPreviousButton() {
      return this.currentPage > 0
    },
    displayedCategories() {
      const start = this.currentPage * this.categoriesPerPage
      const end = start + this.categoriesPerPage
      return this.categories.slice(start, end)
    },
    showNextButton() {
      return this.currentPage < Math.ceil(this.categories.length / this.categoriesPerPage) - 1
    },
    async created() {
      this.metaTag()
    },
    memberInfo() {
      return this.$store.state.member.info
    },
  },
  watch: {
    memberInfo() {
      return this.$store.state.member.info
    },
    checkingPointsResponse() {
      let response = this.$store.state.reward.verifyPoints;
      if (response.code != 0 ) {
        if(!response.success) {
          console.log(response.code + ' failed');
          // console.log(response)
          this.openAlert(response.success, errorCodeHelper.getErrorCodeDesc(response.code));
        }
      }
      else {
        console.log(response.code + ' success');
      }
    },
    checkingRedeemResponse() {
      let response = this.$store.state.reward.redeemProduct;
      if (response.code != 0 ) {
        if(!response.success) {
          console.log(response.code + ' failed to redeem');
          // console.log(response)
          this.openAlert(response.success, errorCodeHelper.getErrorCodeDesc(response.code));
        }
      }
      else {
        console.log(response.code + ' success to redeem');
      }
    },

  },
  created() {
    this.displayAll()
  },
  // mounted() {
  //   window.addEventListener('resize', this.handleResize);
  // },
  methods: {
    truncatedRewardItemName(itemName) {
      const words = itemName.split(' ').slice(0, this.wordLimit).join(' ');
      if (itemName.split(' ').length > this.wordLimit) {
        return words + '...';
      } else {
        return words;
      }
    },
    hideDocumentScrolling(){
      document.body.style.overflow = 'hidden';
    },
    showDocumentScrolling(){
      document.body.style.overflow = 'auto';
    },
    async handleClaimClick(product) {
      if (!this.isLoggedIn) {
        this.$parent.openLoginDialog();
      } else {
        try {
          const selectedProduct = this.reward.find(item => item.id === product.id);
          this.selectedItemId = selectedProduct.id;

          await this.checkingMemberPoint();

          let response = this.$store.state.reward.verifyPoints;

          if (response.success === false) {
            console.log('Error Code = ' + response.code + ' Insufficient Coins');
            this.openAlert(response.success, errorCodeHelper.getErrorCodeDesc(response.code));
            await this.$store.dispatch(`${MEMBER_RESET_VERIFY_POINTS}`);
            console.log("applied reset verify points")


            // Handle success case with insufficient coins, or remove this line if not needed
          } else {
            console.log('Code = ' + response.code + ' Verified sufficient Coins');
            this.claimNow(product.id)
            // Perform any additional actions or handle the failure case
          }
        } catch (error) {
          console.error('Error while handling claim click:', error);
        }
      }
    },

    async checkingMemberPoint() {
      this.$store.state.reward.verifyPoints.success = false;
      let filter = {
        reward_id: this.selectedItemId
      };

      try {
        await this.$store.dispatch(`${MEMBER_VERIFY_POINTS}`, { filter });
      } catch (error) {
        console.error('Error while verifying points:', error);
        // Handle the error as needed, e.g., show an error message
      }
    },
    openAlert(success, msg) {
      this.alertSuccess = success;
      this.alertMessage = msg;
      this.alertShow = true;
    },
    getCurrentDateTime() {
      const currentDate = new Date();
      const dateOptions = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };
      const timeOptions = { hour: '2-digit', minute: '2-digit' };

      const formattedDate = currentDate.toLocaleDateString('en-BD', dateOptions);
      const formattedTime = currentDate.toLocaleTimeString('en-BD', timeOptions);

      return `${formattedDate}, ${formattedTime}`;
    },
    populateAnnouncement(targetPage) {
      this.searchCriteria.pageNumber = targetPage
      let d = this.storageAnnouncement
      this.populatedAnnouncement = d.slice((this.searchCriteria.pageNumber - 1) * 10, this.searchCriteria.pageNumber * 10)
      this.pagination.totalPage = Math.ceil(d.length / this.searchCriteria.pageSize)
    },
    changePage(targetPage) {
      this.selectedPage = targetPage
      this.selectCategoryByPage(this.selectedCategory)
      console.log(targetPage)
    },
    selectOption(option) {
      console.log(option)
      this.selectedVariation = option.variation_objects
      console.log(this.selectedVariation)
      this.selectedOption = option
      // Handle the selection logic here
    },
    nextPage() {
      this.currentPage++
    },
    previousPage() {
      this.currentPage--
    },
    openCoinTipsDialog() {
      this.$parent.coinTipsDialog = true
    },
    closeclaimNowConfirmation() {
      this.claimNowConfirmation = false
      this.showDocumentScrolling()
    },
    closerequestSubmittedDialog() {
      this.requestSubmittedDialog = false
      this.showDocumentScrolling()

    },
    closeNotEnoughBalanceDialog() {
      this.showNotEnoughBalanceDialog = false
      this.showDocumentScrolling()

    },
    resetRedeemProductForm() {
      // Save the current dialCode value
      const currentDialCode = this.redeemProductObj.dialCode;

      // Reset the form properties
      this.redeemProductObj = {
        rewardId: '',
        rewardVariation : '',
        name : '',
        mobile : '',
        email: '',
        city : '',
        postcode : '',
        address1 : '',
        address2 : '',
        currency: SHARED.DEFAULT_CURRENCY,
        dialCode: uiHelper.getDialCode(),
        language: SHARED.DEFAULT_LANGUAGE,
      };

      // Manually reset the v-select component
      this.$refs.redeemProduct_form.resetValidation();

      // If you have other form elements to reset, you can add them here

      // Ensure that the mobileNumberPrefixList is maintained
      this.$nextTick(() => {
        this.redeemProductObj.dialCode = currentDialCode; // Set the dialCode back after the reset
      });
    },
    async redeemNows() {
      if (this.$refs.redeemProduct_form.validate()) {
        this.redeemProductObj.rewardId = this.selectedItemId;
        this.redeemProductObj.rewardVariation = this.selectedVariation;
        // this.redeemProductObj.rewardCategory = this.productSelfCategory;
        let filter = this.redeemProductObj;

        try {
          // Await the completion of the asynchronous action
          await this.$store.dispatch(`${MEMBER_CLAIM_REWARD}`, { filter });

          // The action has completed successfully, check the response
          let response = this.$store.state.reward.redeemProduct;
          if (response.complete && response.code !== 0) {
            console.log('Fill in all the blank!');
            console.log(response.code);
            this.openAlert(response.success, response.message);
          } else {
            this.claimNowConfirmation = false;
            this.resetRedeemProductForm();
            this.requestSubmittedDialog = true;
            this.hideDocumentScrolling()
          }
        } catch (error) {
          console.error('Error while redeeming reward:', error);
        }
      }
      await this.$store.dispatch(`${MEMBER_RESET_CLAIM_REWARD_ITEM}`);
    },
    displayAll() {
      this.selectedCategory = ''
      let categoryId = this.selectedCategory
      let filter = {
        currency: uiHelper.getCurrency(),
        size: 12,
        page: this.selectedPage
      }
      this.$store.dispatch(`${REWARD_GET_ITEM}`, { filter, categoryId })
    },
    selectCategory(category) {
      this.selectedCategory = category
      let categoryId = category
      let filter = {
        currency: uiHelper.getCurrency(),
        size: 12,
        page: 1
      }
      this.$store.dispatch(`${REWARD_GET_ITEM}`, { filter, categoryId })
    },
    selectCategoryByPage(category) {
      this.selectedCategory = category
      let categoryId = category
      let filter = {
        currency: uiHelper.getCurrency(),
        size: 12,
        page: this.selectedPage
      }
      this.$store.dispatch(`${REWARD_GET_ITEM}`, { filter, categoryId })
    },
    claimNow(productId) {
      // Handle claim action...
      if (!this.isLoggedIn) {
        this.$parent.openLoginDialog()
      } else {
        // if (this.memberInfo.rewardBalance <= ) need reward id,
        const selectedProduct = this.reward.find(product => product.id === productId)
        this.selectedItemId = selectedProduct.id
        this.creditGiven = selectedProduct.credit_given
        this.turnoverMultiplier = selectedProduct.turnover_multiplier
        this.selectedProductName = selectedProduct.reward_item_name
        this.productSelfCategory = selectedProduct.reward_category
        this.selectedProductImage = selectedProduct.image_cdn
        this.selectedProductIsHot = selectedProduct.is_hot
        this.coinRequired = selectedProduct.points_required
        this.gotVariations = selectedProduct.variations
        this.showsextraOptions = selectedProduct.extraOptions
        this.claimNowConfirmation = true
        this.hideDocumentScrolling()
      }
    },
    metaTag() {
      console.log('meta-Tag')
      uiHelper.setMetaTag('url', 'https://www.krikya.com/promotion/')
      uiHelper.setTitle(`${locale.getMessage('meta.promotion_title')}`)
      uiHelper.setMetaTag('description', `${locale.getMessage('meta.promotion_description')}`)
      uiHelper.setMetaTag('keywords', `${locale.getMessage('meta.promotion_keyword')}`)
      const currentUrl = window.location.href
      const domainWithProtocol = currentUrl.split('/')[2]
      const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '')
      const path = window.location.pathname
      const cleanPath = path.replace(/\/(en-BD|bn-BD)/i, '')
      const Links = `https://${domain}${cleanPath}`
      const Linksen = `https://${domain}/en-BD${cleanPath}`
      const Linksbn = `https://${domain}/bn-BD${cleanPath}`
      uiHelper.setHreflangLinks(Links)
      uiHelper.setHreflangLinksen(Linksen)
      uiHelper.setHreflangLinksbd(Linksbn)
    },
    getRewardItem(category) {
      let categoryId = category
      let filter = {
        currency: uiHelper.getCurrency(),
        size: 20,
        page: this.selectedPage
      }
      this.$store.dispatch(`${REWARD_GET_ITEM}`, { filter, categoryId })
    }
  }
}
</script>

<style lang="scss">
.confirmation_card_dialog {
  z-index: 999;
  display: block;
}
.confirmation_card_wrapper {
  max-height: 80vh;
  overflow-y: auto;
}
.hot-icon {
  position: absolute;
  top: 0;
  right: 0;
}
.Product-Container {
  text-transform: none !important;
  max-width: 1400px !important;
  //margin: 0px !important;
}
.coinText {
  text-transform: none;
  font-size: 20px;
}
.selectOptions {
  background: #c7c7c7 !important;
  color: #fff !important;
  border-radius: 3px;
  text-transform: none !important;
  font-size: 12px !important;
}
.selected-option {
  background-color: #ff6600 !important;
  color: #ffffff !important;
  border-radius: 3px;
}
.heightIMG {
  height: 280px;
}
.redeemItemText {
  font-size: 14px;
}
.action_class {
  padding-left: 8px !important;
  padding-right: 8px !important;
  .v-btn--disabled {
    width: 100%;
    height: 25px !important;
    font-size: 12px !important;
  }
}
.subtitle_class {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.background-mobile-coin-show {
  padding-top: 20px;
  background: linear-gradient(to bottom, #38946e 65%, #006b52 65%, #f3f3f3 35%);
}
.mobile-coin-show {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
  margin: 0 20px 20px 20px;
  padding: 15px;
}
.detailContainer_mobile {
  max-width: 100%;
  margin: 0 auto; /* Centers the container horizontally */
  background-color: #fff;
  .Product-Container {
    border: none;
  }
  .categoriesContainer {
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
  }
}
.inactive-product {
  filter: grayscale(100%) !important; /* Apply grayscale to inactive products */
  cursor: not-allowed; /* Change cursor for inactive products */
}
.coin-icon {
  height: 30px;
  width: 30px;
}
.redeem-Now {
  background: transparent linear-gradient(180deg, #69c19c 0%, #048265 100%) 0% 0% no-repeat padding-box;
  margin: 15px;
  color: #fff !important;
}
.confirmation_card {
  background-color: #fff;
  padding: 0px;
  //height: 800px;
  .confirmation_card-title {
    background: transparent linear-gradient(180deg, #69c19c 0%, #048265 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px 5px 0px 0px;
    position: sticky;
    top: 0;
  }
  .dialog-close-icon {
    color: #fff !important;
  }
}
.submitted_card {
  background-color: #fff;
  padding: 0px;
  .reqConfirmed_image {
    max-height: 100px;
    max-width: 100px;
    margin: 15px;
  }
  .subtitle {
    letter-spacing: 0px;
    color: #001958;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
  }
  .des {
    font-size: 16px;
    letter-spacing: 0px;
    color: #000000;
  }
  .submitted_card_title {
    background: transparent linear-gradient(180deg, #69c19c 0%, #048265 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px 5px 0px 0px;
  }
  .dialog-close-icon {
    color: #fff !important;
  }
}
.selectedCat {
  color: #ff6600 !important;
  //margin-bottom: 20px;
  border-bottom: 2px solid #ff6600;
  border-radius: 0px !important;
}
.rewardBalance {
  color: #ff6600 !important;
  font-size: 18px;
  font-weight: bolder;
}
.points_required {
  color: #ff6600 !important;
  font-size: 14px;
  font-weight: bolder;
}
.product-container {
  //height: 270px;
  height: 100%;
  text-align: center;
  border: none;
  .v-card__title {
    height: 50px;
    word-break: break-word;
    text-align: left;
    padding: 6px 8px !important;
    font-size: 14px;
    line-height: normal;
    align-items: center;
    justify-content: start;
  }
  .v-card__subtitle {
    padding: 0 8px !important;
    margin-top: 0px !important;
    justify-content: start;
    align-items: center;
    display: flex;
  }
  .claimNow {
    color: #fff !important;
    width: 100%;
    height: 30px !important;
    font-size: 14px !important;
    text-transform: none;
  }
  .coin-icon {
    width: 20px;
    height: auto;
  }
}
.rewardStore-Tab {
  background-color: #fff !important;
  height: 78px !important;
  text-transform: none;
  font-family: 'Readex Pro Deca';
  font-size: 16px !important;
  font-weight: 600; /* or 'medium' if it's working for your case */
  line-height: 28px;
  padding: 0 20px !important;
  //font-size: 20px !important;
}
.rewardStore-Tab::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  border-bottom: 2px solid #ff6600;
  border-radius: 0px;
  opacity: 0;
  transition: bottom 0.2s ease-in, opacity 0.3s ease-out;
  background: url('/static/image/navigator/nav-light.png') no-repeat center bottom;
  background-size: 100% 20%;
}
.rewardStore-Tab_mobile {
  min-width: 23% !important;
  //max-width: 36px;
  font-size: 12px !important;
  background-color: #fff !important;
  text-transform: none;
  font-weight: 500;
  padding: 0 8px !important;
  //font-family: 'Readex Pro Deca';
}
.rewardStore-Tab_mobile::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  border-bottom: 2px solid #ff6600;
  border-radius: 0px;
  opacity: 0;
  transition: bottom 0.2s ease-in, opacity 0.3s ease-out;
  background: url('/static/image/navigator/nav-light.png') no-repeat center bottom;
  background-size: 100% 20%;
}
//.rewardStore-Tab::after {
//  content: '';
//  position: absolute;
//  top: 1.875rem;
//  right: 0;
//  bottom: auto;
//  left: auto;
//  width: 1px;
//  height: 0.875rem;
//  opacity: 1;
//  background: var(--v-background-base);
//}
.rewardStore-Tab:not(:last-child)::after {
  content: '';
  position: absolute;
  //top: 1.875rem;
  right: 0;
  bottom: auto;
  left: auto;
  width: 1px;
  height: 1.875rem;
  opacity: 1;
  background: var(--v-background-base);
}
//.rewardStore-Tab_mobile::after {
//  content: '';
//  position: absolute;
//  //top: 1.875rem;
//  right: 0px;
//  bottom: auto;
//  left: auto;
//  width: 1px;
//  height: 0.875rem;
//  opacity: 1;
//  background: var(--v-background-base);
//}
.rewardStore-Tab_mobile:not(:last-child)::after {
  content: '';
  position: absolute;
  right: 0px;
  bottom: auto;
  left: auto;
  width: 1px;
  height: 0.875rem;
  opacity: 1;
  background: var(--v-background-base);
}
.rewardStore-container {
  background-color: #f3f3f3;
}
.detailContainer {
  position: relative;
  margin: auto;
  border: 4px solid transparent;
  box-sizing: border-box;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 20px 12px;
  max-width: 1400px;
  gap: 30px;

  .Product-Container {
    max-width: 100%;
  }
}

.left-container {
  background-color: #fff;
  flex: 0.3; /* Take up 30% of the width */
  padding: 15px 20px; /* Adjust padding as needed */
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  .coin-icon {
    height: 30px;
    width: 30px;
  }
}
.right-container {
  border-radius: 5px;
  background-color: #fff;
  flex: 1; /* Take up 50% of the width */
  box-shadow: 0px 3px 6px #00000029;
  .v-slide-group__content {
    justify-content: center;
  }
  .v-sheet.v-card {
    box-shadow: none !important;
  }
  .v-btn:before {
    background-color: #fff;
  }
}
.phoneNumberPrefix {
  .v-input__control .v-input__slot {
    padding: 0 8px !important;
    background-color: rgba(0, 0, 0, 0.1) !important;
    .v-input__prepend-inner {
      margin: 0px !important;
    }
  }
  .v-input__append-inner {
    margin: 0px !important;
  }
}
.redeemInfo_field_pn {
  .v-input__control .v-input__slot {
    min-height: 30px !important;
    height: 30px !important;
    font-size: 12px;
  }
}
.redeemInfo_field {
  .v-input__control .v-input__slot {
    border: 2px solid grey;
    padding-left: 8px;
    border-radius: 8px;
    margin-bottom: 2px !important;
    min-height: 30px !important;
    height: 30px !important;
    font-size: 12px;
  }
}
.redeemInfo_field .v-input__control.error--text {
  border-color: red; /* Set the border color to red or any other desired color */
}
@media screen and (max-width: 360px){
  .rewardStore-Tab_mobile {
    min-width: 16% !important;
  }
}
@media screen and (max-width: 420px) {
  .rewardStore-Tab_mobile {
    font-size: 12px !important;
  }
  .heightIMG {
    height: 220px;
  }
  .product-container .v-card__title {
    font-size: 12px;
    padding: 8px;
  }
  .product-container .v-card__subtitle {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    color: #ff6600 !important;
  }
}

@media screen and (min-width: 420px) {
  .rewardStore-Tab_mobile {
    font-size: 12px !important;
    padding: 0 16px !important;
    //font-family: 'Readex Pro Deca';
  }
}
@media screen and (min-width: 520px) {
  .rewardStore-Tab_mobile {
    font-size: 14px !important;
    padding: 0 20px !important;
    //font-family: 'Readex Pro Deca';
  }
}
</style>
